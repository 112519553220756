const dataSourceMapping = [
  {
    type: 'sql',
    subtype: 'drill',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-apache-drill-iframe',
  },
  {
    type: 'sql',
    subtype: 'hive',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-apache-hive-iframe',
  },
  {
    type: 'sql',
    subtype: 'athena',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-amazon-web-services-aws-athena-iframe',
  },
  {
    type: 'sql',
    subtype: 'bigquery',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-google-bigquery-iframe',
  },
  {
    type: 'fs',
    subtype: 'box',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-box-iframe',
  },
  {
    type: 'cassandra',
    subtype: 'cassandra',
    version: '2',
    locale: ['en'],
    slug: 'references-connectors-cassandra-iframe',
  },
  {
    type: 'sql',
    subtype: 'cassandra',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-cassandra-simba-iframe',
  },
  {
    type: 'cdata',
    subtype: 'cdata',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-custom-cdata-iframe',
  },
  {
    type: 'ciscoMeraki',
    subtype: 'ciscoMeraki',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-cisco-meraki-iframe',
  },
  {
    type: 'cosmosdb',
    subtype: 'cosmosdb',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-cosmos-db-iframe',
  },
  {
    type: 'sql',
    subtype: 'custom',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-custom-sql-iframe',
  },
  {
    type: 'datalake',
    subtype: 'azure-gen2',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-microsoft-azure-gen2-iframe',
  },
  {
    type: 'datalake',
    subtype: 'hdfs',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-apache-hadoop-iframe',
  },
  {
    type: 'datalake',
    subtype: 'local',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-local-files-iframe',
  },
  {
    type: 'fs',
    subtype: 'dropbox',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-dropbox-iframe',
  },
  {
    type: 'dynamodb',
    subtype: 'dynamodb',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-amazon-web-services-aws-dynamodb-iframe',
  },
  {
    type: 'datalake',
    subtype: 's3',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-amazon-web-services-aws-s3-iframe',
  },
  {
    type: 'essbase',
    subtype: 'essbase',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-oracle-essbase-iframe',
  },
  {
    type: 'freshservice',
    subtype: 'freshservice',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-freshservice-iframe',
  },
  {
    type: 'fs',
    subtype: 'googledrive',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-google-drive-iframe',
  },
  {
    type: 'googlesheets',
    subtype: 'googlesheets',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-google-sheets-iframe',
  },
  {
    type: 'sql',
    subtype: 'db2',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-ibm-db2-iframe',
  },
  {
    type: 'jira',
    subtype: 'jira',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-jira-iframe',
  },
  {
    type: 'json',
    subtype: 'json',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-json-iframe',
  },
  {
    type: 'kafka',
    subtype: 'kafka',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-apache-kafka-iframe',
  },
  {
    type: 'fs',
    subtype: 'local',
    version: '1',
    locale: ['en'],
    slug: '',
  },
  {
    type: 'sql',
    subtype: 'mongobi',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-mongodb-bi-iframe',
  },
  {
    type: 'sql',
    subtype: 'mysql',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-mysql-iframe',
  },
  {
    type: 'sql',
    subtype: 'netezza',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-ibm-netezza-iframe',
  },
  {
    type: 'sql',
    subtype: 'netsuite',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-netsuite-suiteanalytics-iframe',
  },
  {
    type: 'netsuite',
    subtype: 'netsuite',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-netsuite-webservices-iframe',
  },
  {
    type: 'onelogin',
    subtype: 'onelogin',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-onelogin-iframe',
  },
  {
    type: 'sql',
    subtype: 'oracle',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-oracle-iframe',
  },
  {
    type: 'oracleb2c',
    subtype: 'oracleb2c',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-oracle-b2c-service-iframe',
  },
  {
    type: 'fusion',
    subtype: 'fusion',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-oracle-cloud-applications-iframe',
  },
  {
    type: 'oraclesalescloud',
    subtype: 'oraclesalescloud',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-oracle-sales-cloud-iframe',
  },
  {
    type: 'sql',
    subtype: 'postgresql',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-postgresql-iframe',
  },
  {
    type: 'sql',
    subtype: 'presto',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-presto-iframe',
  },
  {
    type: 'sql',
    subtype: 'redshift',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-amazon-web-services-aws-redshift-iframe',
  },
  {
    type: 'salesforce',
    subtype: 'salesforce',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-salesforce-v1-iframe',
  },
  {
    type: 'salesforce',
    subtype: 'salesforce',
    version: '2',
    locale: ['en'],
    slug: 'references-connectors-salesforce-v2-iframe',
  },
  {
    type: 'sap',
    subtype: 'saperp',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-sap-erp-iframe',
  },
  {
    type: 'sql',
    subtype: 'HANA',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-sap-hana-iframe',
  },
  {
    type: 'sql',
    subtype: 'sybase',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-sap-sybase-iq-iframe',
  },
  {
    type: 'servicenow',
    subtype: 'servicenow',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-servicenow-iframe',
  },
  {
    type: 'sharepoint',
    subtype: 'sharepoint',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-microsoft-sharepoint-iframe',
  },
  {
    type: 'smartsheet',
    subtype: 'smartsheet',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-smartsheet-iframe',
  },
  {
    type: 'sql',
    subtype: 'snowflake',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-snowflake-iframe',
  },
  {
    type: 'splunk',
    subtype: 'splunk',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-splunk-iframe',
  },
  {
    type: 'sql',
    subtype: 'sqlserver',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-microsoft-sql-server-iframe',
  },
  {
    type: 'sql',
    subtype: 'sqlserver-jtds',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-microsoft-sql-server-jtds-iframe',
  },
  {
    type: 'sql',
    subtype: 'teradata',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-teradata-iframe',
  },
  {
    type: 'sql',
    subtype: 'vertica',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-vertica-iframe',
  },
  {
    type: 'xml',
    subtype: 'xml',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-xml-iframe',
  },
  {
    type: 'zuora',
    subtype: 'zuora',
    version: '1',
    locale: ['en'],
    slug: 'references-connectors-zuora-iframe',
  },
];

module.exports = dataSourceMapping;
